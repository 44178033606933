*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  font-size: 1.6rem;
  box-sizing: border-box;
  font-family: sans-serif;
  font-weight: 400;
  line-height: 1.7;
}

p {
  margin-bottom: 2rem;
}

@font-face {
  font-family: "tech";
  src: url("/fonts/tech.ttf");
}

@font-face {
  font-family: "roboto";
  src: url("/fonts/roboto.ttf");
}
